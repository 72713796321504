import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { modulesRoutes } from "Base/constants/routes/modulesRoutes"
import { getAdminRouters } from "Admin/tsx/routes"
import { getTraderRouters } from "Trader/tsx/routes"
import { getAuthRouters } from "Auth/tsx/routes"
import { RootState } from "Starter/store/configureStore"
import { useSelector } from "react-redux"
import { getProfileRouters } from "Profile/tsx/routes"
import { authModuleRoutes } from "Base/constants/routes"
import { getTraderV2Routers } from "TraderV2/tsx/routes"
import { traderV2ModuleRoutes } from "Base/constants/routes/traderV2ModuleRoutes"
import { roleKeys } from "Base/constants/roles"

export const BaseRouter = () => {
  const {
    profilePersist: { profile },
  } = useSelector((state: RootState) => state.base)
  console.log("BaseRouter, profile: ", profile)
  return (
    <Routes>
      {profile && getTraderV2Routers()}
      {profile && getTraderRouters()}
      {profile && getProfileRouters()}
      {profile &&
        profile?.userRoles?.length &&
        profile.userRoles[0].role.roleName === roleKeys.admin &&
        getAdminRouters()}
      {!profile && getAuthRouters()}
      <Route
        path={`${modulesRoutes.root}/*`}
        element={
          <Navigate
            to={profile ? traderV2ModuleRoutes.management.root : authModuleRoutes.auth.login.root}
            replace={false}
          />
        }
      />
    </Routes>
  )
}

import React, { useEffect, useMemo, useState } from "react"
import { MainContent } from "TraderV2/tsx/containers"
import { useDispatch, useSelector } from "react-redux"
import { TraderV2Layout } from "TraderV2/tsx/layouts"
import { PageHeader } from "Base/tsx/containers/PageHeader"
import { Button, SelectCustom } from "Base/tsx/components"
import { TradeFormModal } from "TraderV2/tsx/modals"
import * as platformsActions from "Base/store/trade/platforms/actions"
import * as financialAssetsActions from "Base/store/trade/financialAssets/actions"
import styles from "TraderV2/tsx/pages/Management/Management.module.scss"
import { StringParam, useQueryParam } from "use-query-params"
import { TTradeStatus } from "Base/api/rest/types/trade/trades"
import { FormProvider, useForm } from "react-hook-form"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"
import { RootState } from "Starter/store/configureStore"
import { getIconFinancialAssetsURL, getIconPlatformURL } from "Base/utils/url"
import { PageContainer } from "Base/tsx/containers/PageContainer"

const statusOptions = Object.values(TTradeStatus).map((tradeStatus) => ({
  label: tradeStatus.toUpperCase(),
  value: tradeStatus,
}))

export function Management() {
  const dispatch = useDispatch()
  const { getFinancialAssets } = useSelector((state: RootState) => state.base.financialAssets)
  const { getPlatforms } = useSelector((state: RootState) => state.base.platforms)
  const [isModalVisibleTransactionCreate, setIsModalVisibleTransactionCreate] = useState(false)
  const [tradeStatus, setTradeStatus] = useQueryParam("tradeStatus", StringParam)
  const [tradePrice, setTradePrice] = useQueryParam("tradePrice", StringParam)
  const [tradePlatform, setTradePlatform] = useQueryParam("tradePlatform", StringParam)

  const methods = useForm<{
    status: TOption<string>
    price: TOption<number | string>
    platform: TOption<number | string>
  }>()

  const financialAssetUSDT = useMemo(() => {
    return getFinancialAssets.data?.find((financialAsset) => financialAsset.symbol === "USDT")
  }, [getFinancialAssets.data])

  const tradePriceOptions = useMemo(() => {
    return [
      {
        label: "ALL",
        value: "ALL",
        icon: getIconFinancialAssetsURL(financialAssetUSDT?.id || 3),
      },
      ...[1, 2, 3, 4].map((el) => ({
        label: "price > " + el + " USDT",
        value: el.toString(),
        icon: getIconFinancialAssetsURL(financialAssetUSDT?.id || 3),
      })),
    ]
  }, [financialAssetUSDT])

  const tradePlatformOptions = useMemo(() => {
    return [
      {
        label: "ALL",
        value: "ALL",
      },
      ...(getPlatforms.data?.map((platform) => ({
        label: platform.name,
        value: platform.id.toString(),
        icon: getIconPlatformURL(platform.id),
      })) || []),
    ]
  }, [getPlatforms.data])

  const resetValues = () => {
    methods.reset({
      status:
        statusOptions.find((statusOption) => statusOption.value === tradeStatus) ||
        statusOptions[0],
      price:
        tradePriceOptions.find((tradePriceOption) => tradePriceOption.value === tradePrice) ||
        tradePriceOptions[1],
      platform: tradePlatformOptions.find(
        (tradePlatformOption) => tradePlatformOption.value === tradePlatform,
      ),
    })
  }

  useEffect(() => {
    dispatch(platformsActions.getPlatformsAction.call())
    dispatch(financialAssetsActions.getFinancialAssetsAction.call())

    !tradeStatus && setTradeStatus(TTradeStatus.PURCHASED)
    !tradePrice && setTradePrice("4")
    !tradePlatform && setTradePlatform("ALL")
    console.log("useEffect, tradePlatform: ", tradePlatform)
    resetValues()
  }, [])

  useEffect(() => {
    resetValues()
  }, [tradeStatus, tradePrice, tradePlatform, getPlatforms.data])

  // useEffect(() => {
  //   const API_KEY = "WEHH7T7BH9EH0KYV" // Замените на ваш API ключ
  //   const SYMBOL = "AAPL" // Символ акций Apple
  //   const URL = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${SYMBOL}&apikey=${API_KEY}`
  //
  //   async function getStockData() {
  //     try {
  //       const response = await axios.get(URL)
  //       console.log("getStockData, response.data: ", response.data)
  //     } catch (error) {
  //       console.error("Ошибка при получении данных акции:", error)
  //     }
  //   }
  //
  //   getStockData()
  // }, [])

  // useEffect(() => {
  //   const API_KEY = "WEHH7T7BH9EH0KYV" // Замените на ваш API ключ
  //   const SYMBOL = "AAPL" // Символ акций Apple
  //   const URL = `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${SYMBOL}&apikey=${API_KEY}`
  //
  //   async function getStockData() {
  //     try {
  //       const response = await axios.get(URL)
  //       console.log("getStockData, response.data: ", response.data)
  //     } catch (error) {
  //       console.error("Ошибка при получении данных акции:", error)
  //     }
  //   }
  //
  //   getStockData()
  // }, [])

  // useEffect(() => {
  //   const API_KEY = "clhtalpr01qh8ugj4bv0clhtalpr01qh8ugj4bvg" // Замените на ваш API ключ
  //   const SYMBOL = "AAPL" // Символ акций Apple
  //   const URL = `https://finnhub.io/api/v1/quote?symbol=${SYMBOL}&token=${API_KEY}`
  //
  //   async function getStockData() {
  //     try {
  //       const response = await axios.get(URL)
  //       console.log("getStockData, response.data: ", response.data)
  //     } catch (error) {
  //       console.error("Ошибка при получении данных акции:", error)
  //     }
  //   }
  //
  //   getStockData()
  // }, [])

  // console.log("Management, tradeStatus: ", tradeStatus)

  return (
    <PageContainer>
      <PageHeader>
        <div className={styles.page_header}>
          <div className={styles.control}>
            <FormProvider {...methods}>
              <SelectCustom
                inputWrapperStyle={{ margin: 0 }}
                name='status'
                hideSelectedOptions
                options={statusOptions}
                // @ts-ignore
                onChange={(event) => setTradeStatus(event?.value)}
              />
              <SelectCustom
                disabled={getFinancialAssets.isLoading}
                inputWrapperStyle={{ margin: 0, width: 194 }}
                name='price'
                hideSelectedOptions
                options={tradePriceOptions}
                // @ts-ignore
                onChange={(event) => setTradePrice(event?.value)}
              />
              <SelectCustom
                disabled={getPlatforms.isLoading}
                inputWrapperStyle={{ margin: 0, width: 220 }}
                name='platform'
                hideSelectedOptions
                options={tradePlatformOptions}
                // @ts-ignore
                onChange={(event) => setTradePlatform(event?.value)}
              />
            </FormProvider>
          </div>
          <Button onClick={() => setIsModalVisibleTransactionCreate(true)}>Add trade</Button>
        </div>
      </PageHeader>
      <MainContent />
      <TradeFormModal
        isModalVisible={isModalVisibleTransactionCreate}
        setIsModalVisible={(value) => {
          setIsModalVisibleTransactionCreate(value)
        }}
      />
    </PageContainer>
  )
}

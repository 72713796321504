import React, { useEffect } from "react"
import { SelectCustom } from "Base/tsx/components"
import { FormProvider, useForm } from "react-hook-form"
import { TOption } from "Base/tsx/components/inputs/SelectCustom"
import { useTranslation } from "react-i18next"

interface IFormInput {
  language: TOption<string>
}

const languageOptions = [
  { label: "EN", value: "en" },
  { label: "RU", value: "ru" },
]

export const LanguageChanger = () => {
  const { i18n } = useTranslation()
  const methods = useForm<IFormInput>({ defaultValues: { language: languageOptions[0] } })
  const { watch } = methods

  const language = watch("language")

  useEffect(() => {
    if (!language) return
    console.log("useEffect, language: ", language)
    i18n.changeLanguage(language.value)
  }, [language])

  return (
    <FormProvider {...methods}>
      <SelectCustom<string>
        inputWrapperStyle={{ margin: 0 }}
        name='language'
        hideSelectedOptions
        options={languageOptions}
      />
    </FormProvider>
  )
}

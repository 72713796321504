import { Button, CustomLink } from "Base/tsx/components"
import styles from "./Login.module.scss"
import logo from "Base/assets/icons/logo.svg"
import { LanguageChanger } from "Base/tsx/containers"
import { useTranslation } from "react-i18next"

export const Login = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.login_wrapper}>
      <header>
        <div className={styles.logo}>
          <img height={40} width={40} src={logo} alt='' />
          <h2>Genemer (v{process.env.REACT_APP_VERSION})</h2>
        </div>
        <LanguageChanger />
      </header>
      <div className={styles.form_container}>
        <h2 className={styles.form_title}>{t("t_auth_login_title")}</h2>
        <CustomLink
          to={process.env.REACT_APP_API + "/auth/google"}
          label={<Button fluid>{t("t_auth_login_button")}</Button>}
        />
      </div>
    </div>
  )
}
